import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia, Typography, Box, Link } from '@mui/material';


const rasmus = 'Rasmus L. Haugaard';
const anders = 'Anders G. Buch';
const thorbjørn = 'Thorbjørn M. Iversen';
const simon = 'Simon F. Mathiesen';
const aljaz = 'Aljaz Kramberger';
const jeppe = 'Jeppe Langaa';
const christoffer = 'Christoffer Sloth';


function ResearchPaper(props) {
  const {title, arxiv, projectSite, image, venue, authors, video, code, description, objectPosition='33%'} = props;

  let linkItems = [];
  [['paper', arxiv], ['project site', projectSite], ['video', video], ['code', code]]
  .filter(([name, link], i) => link != null)
  .forEach(([name, link], i) => {
    linkItems.push(<Link key={i*2} href={link}>{name}</Link>)
    linkItems.push(<span key={i*2+1}> / </span>)
  })

  const tprops = {variant: 'body2'};

  return (        
    <Card sx={{display: 'flex'}}>
      <Link display='flex' href={projectSite || arxiv}>
        <img src={image} style={{
          width: '150px', minHeight: '150px', maxWidth: '30vw', 
          objectFit: 'cover', objectPosition,
        }}/>
      </Link>
      <Box sx={{display: 'flex', flexDirection: 'column', alignContent: 'space-between'}}>
        <CardContent>
          <Typography {...tprops} fontWeight='bold'>{title}</Typography>
          <Typography {...tprops}>{authors.join(', ')}</Typography>
          <Typography {...tprops}><i>{venue}</i></Typography>
          <Typography {...tprops}>
            {linkItems.slice(0, -1)}
          </Typography>
          <Typography {...tprops}>{description}</Typography>
        </CardContent>
      </Box>
    </Card>
  )
}


function App() {
  return (
      <Container maxWidth='800px' sx={{
        display: 'flex',
        flexDirection: 'column',
        //gap: '1em',
        padding: '2em',
        background: 'rgb(235, 238, 244)',
        borderRadius: '.5em',
        maxWidth:'800px',
        marginBottom: '2em',
        }}>
        <div class='header' style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: '1em'}}>
          <div>
            <Typography variant='h4' textAlign='center' marginBottom='.5em' lineHeight='1'>
              Rasmus Haugaard
            </Typography>
            <Typography variant='body2' textAlign='center'>
              I'm a PhD student at <Link href='https://www.sdu.dk/en/forskning/sdurobotics'>SDU Robotics</Link>, University of Southern Denmark, 
              where i work on computer vision and machine learning.
            </Typography>
            <Typography variant='body2' textAlign='center'>
              <Link target='_blank' href='mailto:rasmus.l.haugaard@gmail.com'>email</Link> / <span/>
              <Link href='https://www.linkedin.com/in/rasmus-haugaard/'>linkedin</Link> / <span/>
              <Link href='https://github.com/rasmushaugaard'>github</Link> / <span/>
              <Link href='https://scholar.google.com/citations?user=x7gNl9YAAAAJ'><nobr>google scholar</nobr></Link>
            </Typography>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-around', background: 'none', alignItems: 'center'}}>
            <div style={{borderRadius:'100%', display:'flex', overflow:'hidden', marginRight: '1em'}}>
              <img src='/portrait_square.png' style={{width: '10em', height: '10em', objectFit: 'cover'}}/>
            </div>
          </div>
        </div>
        
        <Typography variant='h6' sx={{mb: '.2em'}}>
          Research
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
          <ResearchPaper 
            title='Multi-view object pose estimation from correspondence distributions and epipolar geometry'
            image='/episurfemb.png' objectPosition='50%'
            venue='Under review'
            arxiv='https://arxiv.org/abs/2210.00924'
            authors={[rasmus, thorbjørn]}
          />
          <ResearchPaper 
            title='SurfEmb: Dense and Continuous Correspondence Distributions for Object Pose Estimation with Learnt Surface Embeddings'
            image='/surfemb.png'
            venue='CVPR, 2022'
            arxiv='https://arxiv.org/abs/2111.13489' 
            projectSite='https://surfemb.github.io/'
            code='https://github.com/rasmushaugaard/surfemb'
            authors={[rasmus, anders]}
          />
          <ResearchPaper 
            title='Self-supervised deep visual servoing for high precision peg-in-hole insertion'
            image='/ssvs.png'
            venue='CASE, 2022'
            video='https://drive.google.com/file/d/1EsjKGSGYtfPhaLEyQj7I3yBoeBnNsBBi/view?usp=sharing'
            arxiv='https://arxiv.org/abs/2206.08800'
            authors={[rasmus, anders, thorbjørn]}
          />
          <ResearchPaper 
            title='A Flexible and Robust Vision Trap for Automated Part Feeder Design'
            image='/visiontrap.png'
            venue='IROS, 2022'
            video='https://drive.google.com/file/d/1gBUC58JFWhYJLv7M4sRR3KDUCgJ-rAXy/view?usp=sharing'
            arxiv='https://arxiv.org/abs/2206.00373'
            authors={[rasmus, thorbjørn, anders, aljaz, simon]}
          />
          <ResearchPaper 
            title='Ki-Pode: Keypoint-based Implicit Pose Distribution Estimation of Rigid Objects'
            image='/kipode.png' objectPosition='80%'
            venue='BMVC, 2022'
            arxiv='https://arxiv.org/abs/2209.09659' 
            authors={[thorbjørn, rasmus, anders]}
          />
          <ResearchPaper 
            title='Fast robust peg-in-hole insertion with continuous visual servoing'
            image='/peginhole.png'
            venue='CORL, 2020'
            arxiv='https://arxiv.org/abs/2011.06399'
            video='https://www.youtube.com/watch?v=mpe2kFZhO6I'
            code='https://github.com/rasmushaugaard/peg-in-hole-visual-servoing'
            authors={[rasmus, jeppe, christoffer, anders]}
          />
        </Box>

        <br/>
        <Typography variant='h6' sx={{mb: '.2em'}}>
          Education
        </Typography>
        <Typography variant='body2'>
          M.Sc. w/ honours in Engineering, Advanced Robotics, 2018-2021<br/>
          University of Southern Denmark (SDU) <br/>
          <br/>
          B.Sc. in Engineering, Robot Systems, 2015-2018<br/> 
          University of Southern Denmark (SDU) <br/>
          Exchange, Norwegian University of Science and Technology (NTNU), Fall 2017
        </Typography>

        <div hidden={true}>
          <br/>
          <Typography variant='h6' sx={{mb: '.2em'}}>
            Misc
          </Typography>
          <Typography variant='body2'>
            Winner of the <Link href='https://rpal.cse.usf.edu/competition_iros2020/'>IROS 2020, Robotic Grasping and Manipulation Competition</Link>,
            as part of the <Link href='https://www.youtube.com/watch?v=jdm5_iWpuuQ'>SDU team</Link>, responsible for computer vision and cable routing.<br/>
          </Typography>
        </div>
      </Container>
  );
}

export default App;
